<template>
    <div id="app">
        <nav-bar></nav-bar>
        <div class="main-wrapper">
            <router-view />
        </div>
        <footer-bar></footer-bar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'

export default {
    components: { NavBar, FooterBar },

    computed: {
        ...mapGetters(['seo']),
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/02-Tools/_variables.scss';
@import '@/assets/styles/main.scss';
</style>
