<template>
    <footer id="contact" class="footerbar">
        <div class="first">
            <div class="title-intro">
                <p class="regular-text">Contact</p>
                <h2 class="title"><span v-html="$t('Votre partenaire<br />en stratégie numérique.')"></span></h2>
            </div>

            <div class="bottom">
                <a
                    href="https://www.google.com/maps/place/Wilson+Chambers+Building,+635+Notre-Dame+St+W,+Montreal,+QC+H2Y+2H6,+Canada/@45.5007533,-73.5621848,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc91a595ee54c35:0xa3461924577b178c!8m2!3d45.5007533!4d-73.5599961"
                    target="_blank"
                >
                    <address class="regular-text">
                        <span v-html="$t('635 Notre-Dame Ouest<br />Montréal, Québec, H2Y 2H6') "></span>
                    </address>
                </a>
                <div class="contact-infos">
                    <a href="mailto:contact@elmire.ca">contact@elmire.ca</a>
                </div>
                <div class="right">
                    <a class="google-badge" href="https://www.google.com/partners/agency?id=8442328188" target="_blank">
                        <img src="https://www.gstatic.com/partners/badge/images/2021/PartnerBadgeClickable.svg"/>
                    </a>
                    <a href="https://www.linkedin.com/company/elmire/" target="_blank"
                        ><img src="@/assets/img/linkedin.svg" alt="Linkedin logo"
                    /></a>
                    <div class="cta no-border" @click.prevent="showform"><p>{{ $t('Écrivez-nous') }}</p></div>
                </div>
            </div>
        </div>

        <div class="second">
            <nav>
                <a href="/services/publicite-en-ligne" class="regular-text big"
                    >{{ $t('Publicité en ligne') }}</a
                >
                <a href="/services/publicite-en-ligne#a-serv-1" class="regular-text"
                    >{{ $t('Campagne publicitaire') }}</a
                >
                <a href="/services/publicite-en-ligne#a-serv-2" class="regular-text"
                    >{{ $t('Programmatique') }}</a
                >
                <a href="/services/publicite-en-ligne#a-serv-3" class="regular-text"
                    >{{ $t('Référencement payant') }}</a
                >
                <a href="/services/publicite-en-ligne#a-serv-4" class="regular-text"
                    >{{ $t('Publicité Facebook') }}</a
                >
                <a href="/services/publicite-en-ligne#a-serv-5" class="regular-text"
                    >{{ $t('Publicité Instagram') }}</a
                >
                <a href="/services/publicite-en-ligne#a-serv-6" class="regular-text"
                    >{{ $t('Publicité YouTube') }}</a
                >
            </nav>
            <nav>
                <a href="/services/referencement" class="regular-text big">{{ $t('Référencement') }}</a>
                <a href="/services/referencement#a-serv-1" class="regular-text"
                    >{{ $t('Référencement Web') }}</a
                >
                <a href="/services/referencement#a-serv-2" class="regular-text">{{ $t('Audit SEO') }}</a>
                <a href="/services/referencement#a-serv-3" class="regular-text"
                    >{{ $t('Analyse des contenus') }}</a
                >
                <a href="/services/referencement#a-serv-4" class="regular-text"
                    >{{ $t('Création de contenu') }}</a
                >
                <a href="/services/referencement#a-serv-5" class="regular-text"
                    >{{ $t('Optimisation des balises') }}</a
                >
                <a href="/services/referencement#a-serv-6" class="regular-text"
                    >{{ $t('Référencement local') }}</a
                >
                <a href="/services/referencement#a-serv-7" class="regular-text">{{ $t('Formation SEO') }}</a>
                <a href="/services/referencement#a-serv-8" class="regular-text"
                    >{{ $t('Migration de sites') }}</a
                >
            </nav>
            <nav>
                <a href="/services/conception-web" class="regular-text big">{{ $t('Conception web') }}</a>
                <a href="/services/conception-web#a-serv-1" class="regular-text"
                    >{{ $t('Conception de site web') }}</a
                >
                <a href="/services/conception-web#a-serv-2" class="regular-text"
                    >{{ $t('Site web vitrine') }}</a
                >
                <a href="/services/conception-web#a-serv-3" class="regular-text"
                    >{{ $t('Commerce en ligne') }}</a
                >
                <a href="/services/conception-web#a-serv-4" class="regular-text"
                    >{{ $t('Page de destination') }}</a
                >
                <a href="/services/conception-web#a-serv-5" class="regular-text">{{ $t('Conversions') }}</a>
                <a href="/services/conception-web#a-serv-6" class="regular-text"
                    >{{ $t('Expérience utilisateur') }}</a
                >
                <a href="/services/conception-web#a-serv-7" class="regular-text"
                    >{{ $t('Maintenance de site web') }}</a
                >
                <a href="/services/conception-web#a-serv-8" class="regular-text"
                    >{{ $t('Hébergement de site web') }}</a
                >
            </nav>
            <div class="credits">
                <p class="regular-text">{{ $t('Tous droits réservés') }} © elmire 2021.</p>
                <a v-if="$route.meta && $route.meta.lang === 'fr'" href="/politique-de-confidentialite" class="regular-text"
                    >{{ $t('Politique de confidentialité') }}</a
                >
                <a v-else href="/en/privacy-policy" class="regular-text"
                    >{{ $t('Politique de confidentialité') }}</a
                >
            </div>
            <img src="@/assets/img/half-circle-4.svg" class="circle-4" alt="Elmire logo" />
        </div>

        <div class="overlay-form" :class="{ show: form }">
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hideform" />
            <div class="form-wrap">
                <h3 class="title">{{ $t('Écrivez-nous') }}</h3>
                <form action="" method="post" accept-charset="UTF-8" @submit="sendForm($event)">
                    <input type="hidden" name="action" value="contact-form/send" />

                    <input
                        type="text"
                        :placeholder="$t('Prénom et nom')"
                        name="fromName"
                        v-model="formData.fromName"
                        required
                    />
                    <input
                        type="email"
                        :placeholder="$t('Courriel')"
                        name="fromEmail"
                        v-model="formData.fromEmail"
                        required
                    />
                    <input
                        type="text"
                        :placeholder="$t('Sujet')"
                        name="message[Sujet]"
                        v-model="formData.sujet"
                        required
                    />

                    <textarea
                        :placeholder="$t('Message')"
                        name="message[body]"
                        v-model="formData.message"
                        required
                    ></textarea>

                    <input
                        type="submit"
                        class="cta"
                        :class="{ 'is-sending': isSending }"
                        :value="$t('Envoyer')"
                    />
                </form>
            </div>
            <div class="opacity" @click.prevent="hideform"></div>
        </div>
    </footer>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

import 'sweetalert2/src/sweetalert2.scss'

export default {
    data() {
        return {
            form: false,
            isSending: false,
            formData: {},
        }
    },

    methods: {
        showform() {
            if (this.form) {
                this.form = false
            } else {
                this.form = true
            }
        },
        hideform() {
            this.form = false
        },
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            this.formData['formName'] = 'Contact'

            const formData = new FormData()
            formData.append('formName', this.formData.formName)
            formData.append('sujet', this.formData.sujet)
            formData.append('courriel', this.formData.fromEmail)
            formData.append('message', this.formData.message)
            formData.append('nom', this.formData.fromName)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            await axios
                .post(
                    `${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`,
                    formData,
                    config
                )
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: this.$t('Votre message a été envoyé avec succès.'),
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    this.hideform()
                    this.formData = {}
                    this.formMessage = ''

                    setTimeout(() => {
                        this.isSending = false
                    }, 3000)
                })
                .catch(e => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.hideform()
                        this.formData = {}
                        this.formMessage = ''
                        this.isSending = false
                    }, 3000)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/_variables.scss';

.title-intro {
    .regular-text {
        margin-bottom: 25px;
    }
}

.footerbar {
    width: 100%;

    .first {
        padding: 5vw;
        background-color: $color1;
        color: #fff;
        position: relative;
        z-index: 98;
    }

    .title-intro {
        margin-bottom: 10vw;
        .title {
            font-size: 30px;
        }
    }

    .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;

        address {
            font-style: normal;
            transition: all 0.2s ease;
            color: #fff;

            &:hover {
                color: $color3;
            }
        }

        .contact-infos {
            a {
                color: #fff;
                transition: all 0.2s ease;
                display: block;
                margin-bottom: 5px;

                &:hover {
                    color: $color3;
                }
            }
        }

        .right {
            position: relative;
            display: flex;
            align-items: center;

            img {
                width: 35px;
                margin-right: 30px;
            }

            .google-badge {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(0, -125%);
                width: 135px;
                margin: 0;

                img {
                    display: block;
                    width: 135px;
                    margin: 0;
                }

                @media only screen and (max-width: 700px) {
                    transform: translate(0, 0);
                    top: auto;
                    bottom: 0;
                }

                @media only screen and (max-width: 599px) {
                    width: 75px;
                    margin: 0;

                    img {
                        display: block;
                        width: 75px;
                        margin: 0;
                        margin-bottom: -2px;
                    }
                }
            }

            .cta {
                color: #fff;

                &:hover {
                    color: $color1;
                }

                &.is-sending {
                    opacity: 0.245;
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .title-intro {
            margin-bottom: 20vw;
            .title {
                font-size: 28px;
            }
        }
    }

    @media only screen and (max-width: 700px) {
        .title-intro {
            margin-bottom: 15vw;
            .title {
                font-size: 24px;
            }
        }
        .bottom {
            flex-wrap: wrap;
            address {
                width: 100%;
                margin-bottom: 20px;
            }
            .contact-infos {
                width: 100%;
                margin-bottom: 20px;
            }
            .right {
                width: 100%;
            }
        }
        .first {
            padding: 10vw 5vw;
        }
    }

    .second {
        width: 100%;
        position: relative;
        padding: 5vw;
        display: flex;
        flex-wrap: wrap;
        z-index: 10;
        background-color: $color5;

        .circle-4 {
            position: absolute;
            right: 0;
            top: -40px;
            width: 180px;
        }

        nav {
            width: 30%;
            margin-right: 20px;
            position: relative;
            z-index: 98;

            a {
                color: $color1;
                display: block;
                width: fit-content;
                margin-bottom: 10px;
                transition: all 0.2s ease;
                position: relative;
                &.big {
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    &:before {
                        top: 22px;
                    }
                }
                &:before {
                    content: '';
                    display: block;
                    width: 0%;
                    height: 3px;
                    background-color: $color3;
                    position: absolute;
                    left: -10px;
                    top: 15px;
                    z-index: -1;
                    transition: all 0.3s ease;
                }
                &:hover {
                    &:before {
                        width: calc(100% + 20px);
                    }
                }
            }
        }

        .credits {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5vw;

            a {
                color: $color1;
                transition: all 0.2s ease;

                &:hover {
                    color: $color2;
                }
            }
        }

        @media only screen and (max-width: 1000px) {
            nav {
                a {
                    &.big {
                        font-size: 18px;
                    }
                }
            }
        }

        @media only screen and (max-width: 700px) {
            padding: 10vw 5vw;
            nav {
                width: 100%;
                margin-bottom: 40px;
            }
        }

        @media only screen and (max-width: 650px) {
            .credits {
                flex-wrap: wrap;

                a {
                    width: 100%;
                    margin-top: 10px;
                }
            }
            .circle-4 {
                width: 120px;
            }
        }
    }
}
</style>
