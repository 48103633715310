import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
    {
        path: '/en',
        name: 'HomePage-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'en', json: 'home' },
    },
    {
        path: '/services/conception-web',
        name: 'conception-web-fr',
        component: () => import(/* webpackChunkName: "services" */ '../views/Conceptionweb.vue'),
        meta: { lang: 'fr', json: 'servicesItems', slug: 'conception-web' },
    },
    {
        path: '/en/services/web-design',
        name: 'conception-web-en',
        component: () => import(/* webpackChunkName: "Services" */ '../views/Conceptionweb.vue'),
        meta: { lang: 'en', json: 'servicesItems', slug: '' },
    },
    {
        path: '/services/referencement',
        name: 'Referencement-fr',
        component: () => import(/* webpackChunkName: "services" */ '../views/Referencement.vue'),
        meta: { lang: 'fr', json: 'servicesItems', slug: 'referencement' },
    },
    {
        path: '/en/services/seo',
        name: 'Referencement-en',
        component: () => import(/* webpackChunkName: "Services" */ '../views/Referencement.vue'),
        meta: { lang: 'en', json: 'servicesItems', slug: '' },
    },
    {
        path: '/services/publicite-en-ligne',
        name: 'Publicite-en-ligne-fr',
        component: () => import(/* webpackChunkName: "services" */ '../views/Publicite.vue'),
        meta: { lang: 'fr', json: 'servicesItems', slug: 'publicite-en-ligne' },
    },
    {
        path: '/en/services/online-advertising',
        name: 'publicite-en-ligne-en',
        component: () => import(/* webpackChunkName: "Services" */ '../views/Publicite.vue'),
        meta: { lang: 'en', json: 'servicesItems', slug: '' },
    },
    {
        path: '/politique-de-confidentialite',
        name: 'politique-de-confidentialite-fr',
        component: () => import(/* webpackChunkName: "services" */ '../views/Politique.vue'),
        meta: { lang: 'fr', json: 'politiques' },
    },
    {
        path: '/en/privacy-policy',
        name: 'politique-de-confidentialite-en',
        component: () => import(/* webpackChunkName: "Services" */ '../views/Politique.vue'),
        meta: { lang: 'en', json: 'home' },
    },
    {
        path: '*',
        name: 'p404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        meta: { lang: 'en', json: 'home' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    Store.dispatch('resetContent')

    let type = 'pages'
    const { meta } = to
    let slug = meta.json
    let item = ''

    if (meta.slug) {
        item = meta.slug
        type = '' // Use slug and ignore type
    }

    Store.dispatch('getContent', { lang: meta.lang, slug, type, item })
    next()
})

export default router
