<template>
    <div class="header-wrap">
        <header class="navbar">
            <a href="/" v-if="$route.meta && $route.meta.lang === 'fr'"
                ><img
                    width="180"
                    height="46"
                    src="@/assets/img/logo.svg"
                    class="logo"
                    alt="Logo Elmire"
            /></a>
            <a href="/en" v-else
                ><img
                    width="180"
                    height="46"
                    src="@/assets/img/logo.svg"
                    class="logo"
                    alt="Logo Elmire"
            /></a>
            <div class="right">
                <nav class="desktop-nav" v-if="$route.name && $route.name.includes('Home')">
                    <a href="#expertises" v-scroll-to:80>{{ $t("Expertises") }}</a>
                    <a href="#projets" v-scroll-to:-50>{{ $t("Projets") }}</a>
                    <a href="#equipe" v-scroll-to:-120 @click="dbclickOnce">{{ $t("Culture") }}</a>
                    <a href="#carrieres" v-scroll-to:-80 @click="dbclickOnce">{{ $t("Carrières") }}</a>
                </nav>

                <nav class="desktop-nav" v-else>
                    <a href="/#a-expertises">{{ $t("Expertises") }}</a>
                    <a href="/#a-projets">{{ $t("Projets") }}</a>
                    <a href="/#a-equipe" @click="dbclickOnce">{{ $t("Culture") }}</a>
                    <a href="/#a-carrieres" @click="dbclickOnce">{{ $t("Carrières") }}</a>
                </nav>

                <a v-if="$route.meta && $route.meta.lang === 'fr'" href="/en" class="lang">EN</a>
                <a v-else href="/" class="lang">FR</a>

                <a href="#contact" v-scroll-to:-80 class="cta" @click="dbclickOnce"
                    ><p>{{ $t("Contact") }}</p></a
                >

                <div
                    id="hamburger"
                    @click.prevent.stop="
                        () => {
                            mobileMenuOpen = !mobileMenuOpen
                        }
                    "
                    :class="{ open: mobileMenuOpen }"
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
        <div class="mobile-nav-wrap" :class="{ open: mobileMenuOpen }">
            <nav class="mobile-nav" v-if="$route.name && $route.name.includes('Home')">
                <a
                    href="#expertises"
                    @click.prevent.stop="
                        () => {
                            mobileMenuOpen = !mobileMenuOpen
                        }
                    "
                    v-scroll-to:80
                    >{{ $t("Expertises") }}</a
                >
                <a
                    href="#projets"
                    @click.prevent.stop="
                        () => {
                            mobileMenuOpen = !mobileMenuOpen
                        }
                    "
                    v-scroll-to:-50
                    >{{ $t("Projets") }}</a
                >
                <a
                    href="#equipe"
                    @click.prevent.stop="
                        () => {
                            mobileMenuOpen = !mobileMenuOpen
                        }
                    "
                    v-scroll-to:-120
                    >{{ $t("Culture") }}</a
                >
                <a
                    href="#carrieres"
                    @click.prevent.stop="
                        () => {
                            mobileMenuOpen = !mobileMenuOpen
                        }
                    "
                    v-scroll-to:-80
                    >{{ $t("Carrières") }}</a
                >
                <a
                    href="#contact"
                    @click.prevent.stop="
                        () => {
                            mobileMenuOpen = !mobileMenuOpen
                        }
                    "
                    class="special"
                    v-scroll-to:-80
                    >{{ $t("Contact") }}</a
                >
            </nav>

            <nav class="mobile-nav" v-else>
                <a href="/#a-expertises">{{ $t("Expertises") }}</a>
                <a href="/#a-projets">{{ $t("Projets") }}</a>
                <a href="/#a-equipe">{{ $t("Culture") }}</a>
                <a href="/#a-carrieres">{{ $t("Carrières") }}</a>
                <a href="/#a-contact" class="special">{{ $t("Contact") }}</a>
            </nav>

            <a v-if="$route.meta && $route.meta.lang === 'fr'" href="/en" class="lang">EN</a>
            <a v-else href="/" class="lang">FR</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mobileMenuOpen: false,
            dbclick: true,
        }
    },
    methods: {
        dbclickOnce(e) {
            if (!this.dbclick) {
                return
            }
            this.dbclick = false
            setTimeout(() => {
                e.target.click()
            }, 875)
        },
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.navbar')) {
                return
            }

            if (st >= 50) {
                document.querySelector('.navbar').classList.add('sticky')
            } else {
                document.querySelector('.navbar').classList.remove('sticky')
            }
        },
    },
    created() {
        // NavBar Follow
        this.toogleNavBarStatus()
        window.addEventListener('scroll', () => {
            this.toogleNavBarStatus()
        })
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/_variables.scss';

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 990;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 5vw;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
    transition: all 0.4s ease;

    &.sticky {
        padding: 15px 2vw;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        .logo {
            width: 120px;
        }
        .cta {
            padding: 12px 20px;
        }
    }

    .logo {
        width: 180px;
        transition: all 0.4s ease;
    }

    .right {
        display: flex;
        align-items: center;
    }

    .desktop-nav {
        margin-right: 20px;
        a {
            color: $color1;
            margin-right: 3vw;
            font-size: 14px;
            position: relative;

            &:before {
                content: '';
                display: block;
                width: 0%;
                height: 3px;
                background-color: $color3;
                position: absolute;
                left: -10px;
                top: 9px;
                z-index: -1;
                transition: all 0.3s ease;
            }
            &:hover {
                &:before {
                    width: calc(100% + 20px);
                }
            }
        }
    }

    .lang {
        color: $color1;
        margin-right: 20px;
        font-size: 14px;
    }

    @media only screen and (max-width: 800px) {
        padding: 20px 5vw;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        &.sticky {
            padding: 20px 5vw;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            .logo {
                width: 120px;
            }
        }
        .logo {
            width: 120px;
        }
        .desktop-nav {
            display: none;
        }
        .right {
            .lang {
                display: none;
            }
            .cta {
                display: none;
            }
        }
    }
}

#hamburger {
    width: 30px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: none;
    margin-left: 25px;

    @media only screen and (max-width: 800px) {
        display: block;
    }
}

#hamburger span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: $color2;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

#hamburger span:nth-child(1) {
    top: 0px;
}

#hamburger span:nth-child(2),
#hamburger span:nth-child(3) {
    top: 8px;
}

#hamburger span:nth-child(4) {
    top: 16px;
}

#hamburger.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#hamburger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#hamburger.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.mobile-nav-wrap {
    width: 100%;
    height: calc(100% - 70px);
    position: fixed;
    top: 70px;
    right: -100%;
    background-color: $color2;
    z-index: 1000000000000000;
    padding: 8vw 5vw;
    transition: all 0.3s ease;

    &.open {
        right: 0;
    }

    .mobile-nav {
        width: 100%;
        a {
            width: 100%;
            display: block;
            background-color: rgba(0, 0, 0, 0.1);
            padding: 18px 15px;
            color: #fff;
            margin-bottom: 10px;
            font-size: 14px;
            text-align: center;

            &.special {
                border: 2px solid $color3;
                background-color: transparent;
                color: #fff;
            }
        }
    }

    .lang {
        color: #fff;
        margin-top: 30px;
        display: block;
    }
}
</style>
